import {
  GET_ALL_BADGES_FAILURE,
  GET_ALL_BADGES_REQUEST,
  GET_ALL_BADGES_SUCCESS,
} from '../types';

export interface BadgeValueType {
  notifikasi: number;
  chat: number;
  pesan: number;
  loading: boolean;
}

export interface BadgeActionType {
  type: string;
  notifikasi: number;
  chat: number;
  pesan: number;
}

const initialState: BadgeValueType = {
  notifikasi: 0,
  chat: 0,
  pesan: 0,
  loading: false,
};

export default function badgeReducer(
  state: BadgeValueType = initialState,
  action: BadgeActionType,
): BadgeValueType {
  const { notifikasi, chat, pesan, type } = action;

  if (type === GET_ALL_BADGES_REQUEST) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === GET_ALL_BADGES_SUCCESS) {
    return { notifikasi, pesan, chat, loading: false };
  }

  if (type === GET_ALL_BADGES_FAILURE) {
    return {
      ...state,
      loading: false,
    };
  }

  return state;
}
