import { getListTagihan } from '@/services/tagihan';
import Paging from '@/types/Paging';
import {
  CheckCircleFilled,
  ClockCircleOutlined,
  CloseCircleFilled,
} from '@ant-design/icons';
import { Button, Empty, PageHeader, Select, Skeleton } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Tagihan from '@/types/Tagihan';
import { Link } from 'react-router-dom';

export default function HalamanDaftarTagihan() {
  const [selectedStatus, setSelectedStatus] = useState<string>(null);
  const [listTagihan, setListTagihan] = useState<Paging<Tagihan>>({
    loading: true,
    data: [],
  });

  const handleLoadMore = () => {
    setListTagihan((old) => ({ ...old, loading_more: true }));
    getListTagihan(
      selectedStatus ? `filter[status]=${selectedStatus}` : null,
      listTagihan.current_page + 1,
    )
      .then(({ data }) =>
        setListTagihan((old) => ({
          ...data,
          data: [...old.data, ...data.data],
        })),
      )
      .finally(() => setListTagihan((old) => ({ ...old, loading_more: false })));
  };

  useEffect(() => {
    setListTagihan((old) => ({ ...old, loading: true }));
    getListTagihan(selectedStatus ? `filter[status]=${selectedStatus}` : null)
      .then(({ data }) => setListTagihan(data))
      .finally(() => setListTagihan((old) => ({ ...old, loading: false })));
  }, [selectedStatus]);

  return (
    <>
      <PageHeader
        title="Daftar Tagihan"
        subTitle="Daftar semua tagihan yang tersedia"
        extra={[
          <Select
            key={'tipe-tagihan'}
            placeholder="Status tagihan"
            className="w-48"
            allowClear
            value={selectedStatus}
            onChange={(v) => setSelectedStatus(v === '' ? null : v)}
          >
            <Select.Option key={'DIBUAT'}>Menunggu Pembayaran</Select.Option>
            <Select.Option key={'DIBAYAR'}>Selesai</Select.Option>
            <Select.Option key={'DIBATALKAN'}>Dibatalkan</Select.Option>
          </Select>,
        ]}
      />
      <section className="px-5 pb-5">
        {listTagihan.loading && <Skeleton active />}
        {!listTagihan.loading && (
          <>
            {listTagihan.data.length === 0 && (
              <div className="p-5 bg-white rounded">
                <Empty
                  description={
                    <div className="flex flex-col items-center justify-center">
                      <p className="text-gray-400">Belum ada tagihan tersedia</p>
                    </div>
                  }
                />
              </div>
            )}
            {listTagihan.data.length > 0 && (
              <div className="space-y-5">
                {listTagihan.data.map((tagihan) => (
                  <div key={tagihan.id} className="px-5 py-3 rounded bg-white shadow">
                    <div className="flex justify-between items-center space-x-5 pb-3 border-b border-dashed mb-3">
                      <div className="flex space-x-3 items-center">
                        <img
                          src={tagihan.user_with_role.avatar}
                          alt={tagihan.user_with_role.nama}
                          className="w-9 rounded-full shadow"
                        />
                        <Link
                          to={`/konsumen/${tagihan.user_with_role.user_id}`}
                          className="font-bold text-lg"
                        >
                          {tagihan.user_with_role.nama}
                        </Link>
                      </div>
                      {tagihan.status === 'DIBUAT' && (
                        <span className="text-gray-400">
                          <b className="text-yellow-500">
                            <ClockCircleOutlined />{' '}
                            {moment(tagihan.expired_at).format('Do MMMM yyyy, HH:mm')}
                          </b>
                        </span>
                      )}
                      {tagihan.status === 'DIBATALKAN' && (
                        <b className="text-red-500">
                          <CloseCircleFilled /> Dibatalkan
                        </b>
                      )}
                      {tagihan.status === 'DIBAYAR' && (
                        <b className="text-green-500">
                          <CheckCircleFilled /> Selesai
                        </b>
                      )}
                    </div>
                    <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row md:justify-between pb-3 mb-3 border-b border-dashed">
                      {/* <div className="w-full md:w-3/12 flex items-center"> */}
                      <div>
                        <small className="text-gray-400">Metode Pembayaran</small>
                        <img
                          src={tagihan.metode_pembayaran_logo}
                          alt={tagihan.metode_pembayaran}
                          className="w-20"
                        />
                      </div>
                      {/* </div> */}
                      <div>
                        <small className="text-gray-400">Total Pembayaran</small>
                        <p className="mb-0 font-bold md:text-lg">{tagihan.nilai_diff}</p>
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row space-y-5 md:space-y-0 md:justify-between md:items-center">
                      <div className="flex flex-col md:flex-row space-y-1 md:space-y-0 md:space-x-3">
                        <Link to={`${tagihan.id}`}>
                          <Button className="w-full md:w-auto">
                            Lihat Detail Tagihan
                          </Button>
                        </Link>
                        <Link to={`/chat/${tagihan.user_id}`}>
                          <Button className="w-full md:w-auto">Chat Konsumen</Button>
                        </Link>
                      </div>
                      <span className="text-gray-400 flex">
                        {moment(tagihan.created_at).format('DD MMMM yyyy, HH:mm')}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {listTagihan.next_page_url && (
              <div className="flex items-center justify-center mt-10">
                <Button
                  size="large"
                  shape="round"
                  loading={listTagihan.loading_more}
                  onClick={handleLoadMore}
                >
                  Muat lebih banyak
                </Button>
              </div>
            )}
          </>
        )}
      </section>
    </>
  );
}
