import { getSemuaBadge } from '@/services/badge';
import { GET_ALL_BADGES_FAILURE, GET_ALL_BADGES_SUCCESS } from '@/store/types';
import { call, put } from 'redux-saga/effects';

export function* getAllBadgesHandler() {
  try {
    const { data } = yield call(getSemuaBadge);

    yield put({ type: GET_ALL_BADGES_SUCCESS, ...data });
  } catch (e) {
    yield put({ type: GET_ALL_BADGES_FAILURE });
  }
}
