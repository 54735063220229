import { formatCurrency } from '@/helpers/order_helper';
import {
  getDetailProdukWithDiskon,
  postAddDiskonProduk,
  putToggleDiskonProduk,
  putUpdateDiskonProduk,
} from '@/services/produk';
import Produk from '@/types/Produk';
import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Empty,
  Form,
  Input,
  Modal,
  PageHeader,
  Select,
  Skeleton,
  Switch,
} from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

export default function HalamanDiskonProduk() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [toggling, setToggling] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [produk, setProduk] = useState<Produk | null>(null);
  const [modalItem, setModalItem] = useState<{
    visible: boolean;
    type: 'EDIT' | 'ADD';
    item_id?: number;
    submitting?: boolean;
  }>({
    visible: false,
    type: 'ADD',
  });

  const handleToggleStatus = (diskon_id: number, status: boolean) => {
    setToggling(true);
    putToggleDiskonProduk(produk.id, diskon_id, status)
      .then(({ data }) => {
        setProduk((prev) => ({
          ...prev,
          diskon: data,
        }));
      })
      .finally(() => setToggling(false));
  };

  useEffect(() => {
    // setLoading(true);
    getDetailProdukWithDiskon(id as string)
      .then(({ data }) => setProduk(data))
      .finally(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    if (modalItem.visible && modalItem.item_id) {
      const diskon = produk.diskon.find((item) => item.id === modalItem.item_id);
      form.setFieldsValue({
        ...diskon,
        masa_berlaku: [moment(diskon.dimulai_pada), moment(diskon.berakhir_pada)],
      });
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalItem.visible]);

  if (loading) return <Skeleton active className="p-5" />;

  return (
    <>
      <Modal
        title={`${modalItem.type === 'ADD' ? 'Tambah' : 'Edit'} Diskon`}
        confirmLoading={modalItem.submitting}
        visible={modalItem.visible}
        okText="Simpan"
        cancelText="Batal"
        onOk={() => form.submit()}
        onCancel={() => setModalItem((old) => ({ ...old, visible: false }))}
      >
        <Form
          form={form}
          onFinish={(values) => {
            setModalItem((old) => ({ ...old, submitting: true }));

            if (modalItem.type === 'ADD') {
              postAddDiskonProduk(produk.id, {
                ...values,
                masa_berlaku: values.masa_berlaku.map((item) =>
                  moment(item).format('yyyy-MM-DD'),
                ),
              })
                .then(({ data }) => {
                  setModalItem((old) => ({ ...old, visible: false, submitting: false }));
                  setProduk((prev) => ({
                    ...prev,
                    diskon: data,
                  }));
                })
                .catch(() =>
                  setModalItem((prev) => ({
                    ...prev,
                    submitting: false,
                  })),
                );
            } else {
              putUpdateDiskonProduk(produk.id, modalItem.item_id, {
                ...values,
                masa_berlaku: values.masa_berlaku.map((item) =>
                  moment(item).format('yyyy-MM-DD'),
                ),
              })
                .then(({ data }) => {
                  setModalItem((old) => ({ ...old, visible: false, submitting: false }));
                  setProduk((prev) => ({
                    ...prev,
                    diskon: data,
                  }));
                })
                .catch(() =>
                  setModalItem((prev) => ({
                    ...prev,
                    submitting: false,
                  })),
                );
            }
          }}
          layout={'vertical'}
        >
          <div className="grid grid-cols-12 md:gap-5">
            <div className="col-span-12 md:col-span-4">
              <Form.Item
                label="Tipe Diskon"
                name={'tipe_diskon'}
                rules={[{ required: true, message: 'Tipe Diskon dibutuhkan' }]}
              >
                <Select>
                  <Select.Option value={'PERSENTASE'}>Persentase</Select.Option>
                  <Select.Option value={'NOMINAL'}>Nominal</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="col-span-12 md:col-span-8">
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.disktipe_diskonon !== curValues.tipe_diskon
                }
              >
                {() => (
                  <Form.Item
                    label="Nilai Diskon"
                    name={'nilai'}
                    rules={[
                      {
                        required: true,
                        message: 'Nilai Diskon dibutuhkan',
                      },
                    ]}
                  >
                    <Input
                      addonBefore={
                        form.getFieldValue('tipe_diskon') === 'NOMINAL' && <span>Rp</span>
                      }
                      addonAfter={
                        form.getFieldValue('tipe_diskon') === 'PERSENTASE' && (
                          <span>%</span>
                        )
                      }
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </div>
          </div>
          <Form.Item
            label="Masa Berlaku"
            name={'masa_berlaku'}
            rules={[
              {
                required: true,
                message: 'Masa berlaku dibutuhkan',
              },
            ]}
          >
            <DatePicker.RangePicker
              picker="date"
              className="w-full"
              disabledDate={(date) => {
                return moment().subtract(1, 'day').isAfter(date);
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <PageHeader
        onBack={() => navigate(`/produk/${id}`)}
        breadcrumb={{
          routes: [
            {
              path: '/produk',
              breadcrumbName: 'Daftar Produk',
            },
            {
              path: `/produk/${id}`,
              breadcrumbName: 'Detail',
            },
            {
              path: `/produk/${id}/stok`,
              breadcrumbName: 'Diskon',
            },
          ],
          itemRender: (route, _, routes) => {
            const last = routes.indexOf(route) === routes.length - 1;
            return last ? (
              <span>{route.breadcrumbName}</span>
            ) : (
              <Link to={route.path}>{route.breadcrumbName}</Link>
            );
          },
        }}
        title="Diskon Produk"
        subTitle="Perbaharui nilai diskon produk"
        extra={[
          <Button
            key={'add'}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setModalItem({ visible: true, type: 'ADD' })}
          >
            Tambah Diskon
          </Button>,
        ]}
      />
      <section className="p-5">
        <div className="flex space-x-5 mb-5">
          <div className="flex-none">
            <img
              src={produk?.banner}
              alt={produk?.nama}
              className="w-20 md:w-32 rounded"
            />
          </div>
          <div className="flex-grow flex flex-col justify-center">
            <h1 className="font-bold text-xl md:text-2xl mb-0">{produk?.nama}</h1>
            <p className="flex items-center space-x-3">
              <span className="text-lg">{formatCurrency(produk.harga)}</span>
            </p>
          </div>
        </div>
        <div className="bg-white rounded divide-y">
          {produk?.diskon?.length === 0 && (
            <div className="p-5 rounded">
              <Empty
                description={
                  <>
                    <p className="text-gray-500">Tidak ada diskon tersedia</p>
                  </>
                }
              />
            </div>
          )}
          {produk.diskon.map((item) => (
            <div
              key={item.id}
              className="flex flex-col md:flex-row md:items-center md:justify-between space-y-5 md:space-y-0 md:space-x-5 px-5 py-3"
            >
              <div className="flex-grow">
                <small className="font-semibold">Nilai</small>
                <p className="leading-tight">
                  {item.tipe_diskon === 'NOMINAL'
                    ? formatCurrency(item.nilai)
                    : `${item.nilai}%`}
                </p>
                <small className="font-semibold">Masa berlaku</small>
                <p className="leading-tight mb-0">
                  {moment(item.dimulai_pada).format('DD MMM yyyy')} s/d{' '}
                  {moment(item.berakhir_pada).format('DD MMM yyyy')}
                </p>
              </div>
              <div className="flex items-center space-x-5">
                <Switch
                  checkedChildren="Aktif"
                  unCheckedChildren="Non-aktif"
                  checked={item.is_aktif}
                  loading={toggling}
                  onChange={(checked) => handleToggleStatus(item.id, checked)}
                />
                <Button
                  type="default"
                  shape="round"
                  size="small"
                  onClick={() =>
                    setModalItem({ visible: true, item_id: item.id, type: 'EDIT' })
                  }
                >
                  Ubah
                </Button>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}
