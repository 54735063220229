import ModalKategori, { ModalKategoriItem } from '@/components/ModalKategoriComponent';
import { confirmAlert } from '@/helpers/swal_helper';
import {
  deleteHapusKategoriProduk,
  getKategoriProdukDetail,
  getSemuaKategoriProdukCascader,
  getSemuaProduk,
  postTambahKategoriProduk,
  putUpdateKategoriProduk,
} from '@/services/produk';
import Kategori from '@/types/Kategori';
import Paging from '@/types/Paging';
import Produk from '@/types/Produk';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Empty, message, PageHeader, Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

export default function HalamanDetailKategoriProduk() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [kategori, setKategori] = useState<Kategori | null>(null);
  const [loadingHalaman, setLoadingHalaman] = useState<boolean>(false);
  const [modalKategoriItem, setModalKategoriItem] = useState<ModalKategoriItem | null>(
    null,
  );
  const [produks, setProduks] = useState<Paging<Produk>>({
    data: [],
    loading: true,
  });

  const handleHapusKategori = () => {
    confirmAlert(
      'Hapus Kategori',
      <>
        Apakah anda yakin untuk menghapus kategori <b>{kategori!.nama}</b>
        {kategori!.sub_kategori_simple?.length > 0 && ' dan sub kategorinya'}?
      </>,
    ).then((willDelete: boolean) => {
      if (willDelete) {
        deleteHapusKategoriProduk(id as string).then(() => {
          message.success('Kategori berhasil dihapus');
          navigate('/produk');
        });
      }
    });
  };

  const handleMuatLebihBanyak = () => {
    setLoadingHalaman(true);
    getSemuaProduk(produks.next_page_url, id as string)
      .then(({ data }) => {
        setProduks((old) => ({
          ...data,
          data: [...old.data, ...data.data],
        }));
      })
      .finally(() => setLoadingHalaman(false));
  };

  useEffect(() => {
    getKategoriProdukDetail(id as string).then(({ data }) => {
      setKategori(data);
      setLoading(false);
    });
    getSemuaProduk(null, id as string)
      .then(({ data }) => {
        setProduks((old) => ({
          ...data,
          data: [...old.data, ...data.data],
        }));
      })
      .finally(() => setLoadingHalaman(false));
  }, [id]);

  if (loading) return <Skeleton active className="p-5" />;

  return (
    <>
      <ModalKategori
        tipe="Produk"
        visible={!!modalKategoriItem}
        kategoriItem={modalKategoriItem}
        getCascaderAction={getSemuaKategoriProdukCascader}
        postAddAction={(params) => postTambahKategoriProduk(params)}
        putUpdateAction={(kategori_id, params) =>
          putUpdateKategoriProduk(kategori_id, params)
        }
        onFinishAdd={(kategori: Kategori) => {}}
        onFinishUpdate={(kategori: Kategori) => setKategori(kategori)}
        onCancel={() => setModalKategoriItem(null)}
      />
      <PageHeader
        onBack={() => navigate('/produk')}
        breadcrumb={{
          routes: [
            {
              path: '/produk',
              breadcrumbName: 'Daftar Produk',
            },
            {
              path: `/kategori/produk/${id}`,
              breadcrumbName: 'Detail Kategori Produk',
            },
          ],
          itemRender: (route, _, routes) => {
            const last = routes.indexOf(route) === routes.length - 1;
            return last ? (
              <span>{route.breadcrumbName}</span>
            ) : (
              <Link to={route.path}>{route.breadcrumbName}</Link>
            );
          },
        }}
        title={kategori!.nama}
        subTitle={
          kategori?.parent_kategori
            ? `Sub kategori dari ${kategori.parent_kategori.nama}`
            : null
        }
        extra={[
          <Button
            key={'edit-kategori'}
            type="primary"
            icon={<EditOutlined />}
            onClick={() => setModalKategoriItem({ tipe: 'EDIT', item: kategori! })}
          >
            Perbaharui
          </Button>,
          <Button
            key={'delete-kategori'}
            danger
            type="primary"
            icon={<DeleteOutlined />}
            onClick={handleHapusKategori}
          >
            Hapus Kategori
          </Button>,
        ]}
      />
      <section className="p-5">
        {produks.loading && <Skeleton active />}
        {!produks.loading && (
          <div>
            {produks.data.length === 0 && (
              <Empty
                className="py-5 rounded bg-white"
                description={<p className="text-gray-500">Belum ada produk tersedia</p>}
              />
            )}
            {produks.data.length > 0 && (
              <div className={`grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5`}>
                {produks.data.map((produk) => (
                  <div
                    className={`border rounded bg-white overflow-hidden shadow ${
                      produk.is_active ? '' : 'border-red-500'
                    }`}
                    key={produk.id}
                  >
                    <img src={produk.banner} alt={produk.nama} className="w-full" />
                    <div className="px-5 py-3">
                      <h3 className="leading-tight text-lg mb-0">
                        <Link to={`/produk/${produk.id}`} className="font-bold">
                          {produk.nama}
                        </Link>
                      </h3>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {produks.next_page_url && (
              <div className="flex items-center justify-center mt-10">
                <Button
                  onClick={handleMuatLebihBanyak}
                  loading={loadingHalaman}
                  type="default"
                  shape="round"
                >
                  Muat lebih banyak
                </Button>
              </div>
            )}
          </div>
        )}
      </section>
    </>
  );
}
