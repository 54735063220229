import { Form, Input, Button, PageHeader } from 'antd';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { parseError } from '@/helpers/form_helper';
import { useAppSelector } from '@/hooks/redux_hooks';
import { postUpdatePassword } from '@/services/profile';
import { CheckCircleFilled } from '@ant-design/icons';

export default function HalamanUpdatePassword() {
  const session = useAppSelector((state) => state.sesi.user);
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <>
      <PageHeader
        onBack={() => navigate('/profile')}
        breadcrumb={{
          routes: [
            { path: '/profile', breadcrumbName: 'Profile' },
            { path: '/profile/update-password', breadcrumbName: 'Ubah Password' },
          ],
          itemRender: (route, _, routes) => {
            const last = routes.indexOf(route) === routes.length - 1;
            return last ? (
              <span>{route.breadcrumbName}</span>
            ) : (
              <Link to={route.path}>{route.breadcrumbName}</Link>
            );
          },
        }}
        title="Perbaharui Password"
        subTitle="Tingkatkan keamanan akun dengan mengubah password secara regular"
      />
      <section className="p-5">
        <div className="rounded bg-white p-5 shadow">
          <Form
            form={form}
            className="md:w-6/12"
            layout={'vertical'}
            onFinish={(values) => {
              setLoading(true);
              postUpdatePassword(values)
                .then(() => navigate('/profile'))
                .catch((e: any) => parseError(form, e))
                .finally(() => setLoading(false));
            }}
            initialValues={session!}
          >
            <Form.Item
              name="old_password"
              label="Password Lama"
              rules={[{ required: true, message: 'Password lama diperlukan' }]}
            >
              <Input.Password size="large" />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password Baru"
              rules={[{ required: true, message: 'Password baru diperlukan' }]}
            >
              <Input.Password size="large" />
            </Form.Item>
            <Form.Item
              name="password_confirmation"
              label="Konfirmasi Password Baru"
              rules={[
                { required: true, message: 'Konfirmasi password diperlukan' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Konfirmasi password tidak valid'));
                  },
                }),
              ]}
            >
              <Input.Password size="large" />
            </Form.Item>
          </Form>
          <div className="mt-5">
            <Button
              size="large"
              icon={<CheckCircleFilled />}
              loading={loading}
              onClick={() => form.submit()}
              type="primary"
              className="w-full md:w-auto"
            >
              Simpan Perubahan
            </Button>
          </div>
        </div>
      </section>
    </>
  );
}
