import { formatCurrency } from '@/helpers/order_helper';
import { getChartPendapatan, getChartPendapatanByProduk } from '@/services/pendapatan';
import { Line } from '@ant-design/charts';
import { LoadingOutlined } from '@ant-design/icons';
import { DatePicker, Empty, PageHeader, Select } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Column } from '@ant-design/plots';

export default function HalamanPendapatan() {
  const [dataPendapatan, setDataPendapatan] = useState<Array<any>>([]);
  const [filterPendapatan, setFilterPendapatan] = useState<{
    loading: boolean;
    value: string;
    tipe: string;
  }>({
    loading: false,
    tipe: 'annual',
    value: null,
  });
  const [dataPendapatanProduk, setDataPendapatanProduk] = useState<Array<any>>([]);
  const [filterPendapatanProduk, setFilterPendapatanProduk] = useState<{
    loading: boolean;
    value: string;
    tipe: string;
  }>({
    loading: true,
    value: moment().format('yyyy'),
    tipe: 'annual',
  });

  useEffect(() => {
    setFilterPendapatanProduk((old) => ({ ...old, loading: true }));
    getChartPendapatanByProduk(filterPendapatanProduk.tipe, filterPendapatanProduk.value)
      .then(({ data }) => setDataPendapatanProduk(data))
      .finally(() => setFilterPendapatanProduk((old) => ({ ...old, loading: false })));
  }, [filterPendapatanProduk.tipe, filterPendapatanProduk.value]);

  useEffect(() => {
    setFilterPendapatan((old) => ({ ...old, loading: true }));
    getChartPendapatan(filterPendapatan.tipe, filterPendapatan.value)
      .then(({ data }) => setDataPendapatan(data))
      .finally(() => setFilterPendapatan((old) => ({ ...old, loading: false })));
  }, [filterPendapatan.tipe, filterPendapatan.value]);

  return (
    <section className="pb-5">
      <PageHeader title="Pendapatan" subTitle="Daftar pendapatan dari penjualan produk" />
      <div className="bg-white mx-5 rounded p-5 shadow relative">
        {filterPendapatan.loading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 z-50 rounded text-white">
            <LoadingOutlined className="text-5xl" />
          </div>
        )}
        {!filterPendapatan.loading && dataPendapatan.length === 0 && (
          <div className="absolute inset-0 flex items-center justify-center">
            <Empty
              description={
                <p className="text-gray-400">Tidak ada data untuk ditampilkan</p>
              }
            />
          </div>
        )}
        <div className="flex flex-col lg:flex-row justify-between mb-10 space-y-3 lg:space-y-0 lg:space-x-5">
          <h2 className="text-lg">Pendapatan Secara Umum</h2>
          <div className="flex-none space-y-1 md:space-y-0 md:space-x-5">
            <Select
              className="w-full md:w-auto"
              value={filterPendapatan.tipe}
              loading={filterPendapatan.loading}
              disabled={filterPendapatan.loading}
              onChange={(tipe) =>
                setFilterPendapatan((old) => ({ ...old, tipe, value: null }))
              }
            >
              <Select.Option value={'annual'}>Tampilkan per Tahun</Select.Option>
              <Select.Option value={'monthly'}>Tampilkan per Bulan</Select.Option>
              <Select.Option value={'daily'}>Tampilkan per Hari</Select.Option>
            </Select>
            {filterPendapatan.tipe === 'monthly' && (
              <DatePicker
                className="w-full md:w-auto"
                disabled={filterPendapatan.loading}
                defaultValue={moment()}
                picker="year"
                onChange={(value) => {
                  if (!value) return;
                  setFilterPendapatan((o) => ({
                    ...o,
                    value: value.format('yyyy'),
                  }));
                }}
              />
            )}
            {filterPendapatan.tipe === 'daily' && (
              <DatePicker
                className="w-full md:w-auto"
                disabled={filterPendapatan.loading}
                picker="month"
                defaultValue={moment()}
                onChange={(value) => {
                  if (!value) return;
                  setFilterPendapatan((o) => ({
                    ...o,
                    value: value.format('yyyy-M'),
                  }));
                }}
              />
            )}
          </div>
        </div>

        <Line
          xField="created_at"
          yField="value"
          height={300}
          xAxis={{
            tickCount: 5,
          }}
          yAxis={{
            label: {
              formatter: function (v) {
                return formatCurrency(v);
              },
            },
          }}
          tooltip={{
            customItems: (originalItems) => {
              // process originalItems,
              return originalItems.map((item) => ({
                ...item,
                name: 'Jumlah Pendapatan',
                value: formatCurrency(item.value),
              }));
            },
            customContent: (title, data) => {
              return `<div class="py-2"><h5 class="text-lg mb-0">${data[0]?.title}</h5><span>${data[0]?.value}</span></div>`;
            },
          }}
          data={dataPendapatan}
          padding={'auto'}
        />
      </div>
      <div className="bg-white rounded p-5 relative mx-5 shadow mt-5">
        {filterPendapatanProduk.loading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 z-50 rounded text-white">
            <LoadingOutlined className="text-5xl" />
          </div>
        )}
        {!filterPendapatanProduk.loading && dataPendapatanProduk.length === 0 && (
          <div className="absolute inset-0 flex items-center justify-center ">
            <Empty
              description={
                <p className="text-gray-400">Tidak ada data untuk ditampilkan</p>
              }
            />
          </div>
        )}
        <div className="flex flex-col lg:flex-row justify-between mb-10 space-y-3 lg:space-y-0 lg:space-x-5">
          <h2 className="text-lg">Berdasarkan Produk</h2>
          <div className="flex-none space-y-1 md:space-y-0 md:space-x-5">
            <Select
              className="w-full md:w-auto"
              value={filterPendapatanProduk.tipe}
              loading={filterPendapatanProduk.loading}
              disabled={filterPendapatanProduk.loading}
              onChange={(tipe) =>
                setFilterPendapatanProduk((old) => ({ ...old, tipe, value: null }))
              }
            >
              <Select.Option value={'annual'}>Tampilkan dalam Tahun</Select.Option>
              <Select.Option value={'monthly'}>Tampilkan dalam Bulan</Select.Option>
              <Select.Option value={'daily'}>Tampilkan dalam Hari</Select.Option>
            </Select>
            {filterPendapatanProduk.tipe === 'annual' && (
              <DatePicker
                className="w-full md:w-auto"
                disabled={filterPendapatanProduk.loading}
                defaultValue={moment()}
                picker="year"
                onChange={(value) => {
                  if (!value) return;
                  setFilterPendapatanProduk((o) => ({
                    ...o,
                    value: value.format('yyyy'),
                  }));
                }}
              />
            )}
            {filterPendapatanProduk.tipe === 'monthly' && (
              <DatePicker
                className="w-full md:w-auto"
                disabled={filterPendapatanProduk.loading}
                defaultValue={moment()}
                picker="month"
                onChange={(value) => {
                  if (!value) return;
                  setFilterPendapatanProduk((o) => ({
                    ...o,
                    value: value.format('yyyy-M'),
                  }));
                }}
              />
            )}
            {filterPendapatanProduk.tipe === 'daily' && (
              <DatePicker
                className="w-full md:w-auto"
                disabled={filterPendapatanProduk.loading}
                picker="date"
                defaultValue={moment()}
                onChange={(value) => {
                  if (!value) return;
                  setFilterPendapatanProduk((o) => ({
                    ...o,
                    value: value.format('yyyy-M-DD'),
                  }));
                }}
              />
            )}
          </div>
        </div>
        <Column
          data={dataPendapatanProduk}
          xField="produk"
          yField="value"
          yAxis={{
            label: {
              formatter: function (v) {
                return formatCurrency(v);
              },
            },
          }}
          color={['#d62728', '#2ca02c', '#000000']}
          columnStyle={{
            fill: '#FAAD14',
          }}
          label={false}
          tooltip={{
            showTitle: true,
            showMarkers: false,
            customItems: (originalItems) => {
              // process originalItems,
              return originalItems.map((item) => ({
                ...item,
                name: 'Jumlah Pendapatan',
                value: formatCurrency(item.value),
              }));
            },
            customContent: (title, data) => {
              return `<div class="py-2"><h5 class="text-md">${data[0]?.title}</h5><span>${data[0]?.value}</span></div>`;
            },
          }}
        />
      </div>
    </section>
  );
}
