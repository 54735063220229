import { parseError } from '@/helpers/form_helper';
import { getSemuaKonfigurasi, postUpdateKonfigurasi } from '@/services/konfigurasi';
import Konfigurasi from '@/types/Konfigurasi';
import { Button, Empty, Form, Input, Modal, PageHeader, Skeleton, message } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';

export default function HalamanPengaturanKonfigurasi() {
  const [list, setList] = useState<Array<Konfigurasi>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selected, setSelected] = useState<Konfigurasi>(null);

  const [form] = Form.useForm();

  const handleSubmitChanges = (values: any) => {
    setSubmitting(true);
    postUpdateKonfigurasi(selected?.id, values)
      .then(() => {
        message.success(`${selected?.nama} berhasil diperbaharui`);
        setList((old) =>
          old.map((i) => {
            if (i.id === selected.id) {
              return {
                ...i,
                nilai: values.nilai,
              };
            }
            return i;
          }),
        );
        setSelected(null);
      })
      .catch((e) => parseError(form, e))
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    if (selected) {
      form.setFieldsValue({
        nilai: selected.nilai,
      });
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    getSemuaKonfigurasi()
      .then(({ data }) => setList(data))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <Skeleton active className="p-5" />;
  }

  return (
    <>
      <PageHeader title="Konfigurasi" subTitle="Sesuaikan nilai konfigurasi" />
      <Modal
        confirmLoading={submitting}
        title={`Ubah ${selected?.nama}`}
        visible={!!selected}
        okText="Simpan"
        onOk={() => form.submit()}
        onCancel={() => setSelected(null)}
      >
        <Form layout="vertical" onFinish={handleSubmitChanges} form={form}>
          <Form.Item
            name="nilai"
            label={selected?.nama}
            rules={[{ required: true, message: `${selected?.nama} dibutuhkan` }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      {list.length === 0 && (
        <Empty
          description={<p className="text-gray-400">Tidak ada item konfigurasi</p>}
        />
      )}
      {list.length > 0 && (
        <div className="divide-y px-5 rounded overflow-hidden">
          {list.map((item) => (
            <div key={item.id} className="bg-white px-5 py-3 flex space-x-5">
              <div className="w-4/12 flex flex-col">
                <small className="text-gray-400">{item.nama}</small>
                <span>{item.nilai}</span>
              </div>
              <div className="w-4/12 flex flex-col">
                <small className="text-gray-400">Diperbaharui pada</small>
                <span>{moment(item.updated_at).fromNow()}</span>
              </div>
              <div className="w-4/12 flex justify-end items-center">
                <Button onClick={() => setSelected(item)}>Perbaharui</Button>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
