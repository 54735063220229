import axios from './axios';

export const getAllCategories = () => {
  return axios.get('/kategori');
};

export const getEndCategories = () => {
  return axios.get('/kategori/end');
};

export const getDetailCategory = (id: string) => {
  return axios.get(`/kategori/${id}`);
};
