import { putUpdateStokProduk } from '@/services/produk';
import { CalendarFilled } from '@ant-design/icons';
import { Form, Input, message, Modal, ModalProps } from 'antd';
import { Moment } from 'moment';
import { useEffect, useState } from 'react';

interface Props extends ModalProps {
  produk_id: string;
  stok: {
    tanggal: Moment;
    jumlah: number;
  };
  onFinish: (payload: { jumlah: number; tanggal: Moment }) => void;
}

export default function ModalStokProduk({
  produk_id,
  stok,
  visible,
  onCancel,
  onFinish,
}: Props) {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleSimpanStok = (values) => {
    setSubmitting(true);
    putUpdateStokProduk(produk_id, {
      ...values,
      waktu_tersedia: stok.tanggal.format('yyyy-MM-Do'),
    })
      .then(() => {
        message.success('Stok produk berhasil diperbaharui');
        onFinish({ jumlah: values.jumlah, tanggal: stok.tanggal });
      })
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    if (visible && stok?.jumlah) {
      form.setFieldsValue({
        jumlah: stok.jumlah,
      });
    } else {
      form.resetFields();
    }
  }, [form, stok?.jumlah, visible]);

  return (
    <Modal
      title="Stok Produk"
      visible={visible}
      onCancel={onCancel}
      okText="Simpan"
      onOk={() => form.submit()}
      okButtonProps={{ loading: submitting }}
    >
      {stok && (
        <div className="px-5 py-2 rounded border border-green-700 flex space-x-5 items-center mb-5 text-green-700">
          <CalendarFilled className="text-lg" />
          <span className="font-bold">{stok.tanggal.format('dddd, Do MMMM yyyy')}</span>
        </div>
      )}
      <Form form={form} onFinish={handleSimpanStok} layout="vertical">
        <Form.Item
          name={'jumlah'}
          label="Jumlah Produk"
          rules={[
            { required: true, message: 'Jumlah produk dibutuhkan' },
            { pattern: /^([0-9])*$/, message: 'Jumlah produk harus berupa angka' },
          ]}
        >
          <Input size="large" />
        </Form.Item>
      </Form>
    </Modal>
  );
}
