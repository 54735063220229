import 'webix/webix.css';
import { Button, Pagination, PageHeader, Dropdown, Menu } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import * as webix from '@xbs/webix-pro';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {
  getSemuaKategoriKonsumenOptions,
  getSemuaKonsumen,
  putMakeAllNonTester,
  putMakeAllTester,
  putToggleTester,
} from '@/services/konsumen';
import UserKonsumen from '@/types/UserKonsumen';
import { webixTableParams } from '@/helpers/webix_helper';
import { MoreOutlined, UserSwitchOutlined } from '@ant-design/icons';

export default function HalamanDaftarKonsumenTester() {
  const webixTableRef = useRef<any>();
  const uiTable = useRef<any>();

  const navigate = useNavigate();

  const [toggling, setToggling] = useState<boolean>(false);
  const [konsumen, setKonsumen] = useState<UserKonsumen | null>(null);
  const [page, setPage] = useState<{
    current: number;
    last: number;
    total: number;
    per_page: number;
  }>({ current: 1, last: 1, total: 1, per_page: 15 });

  const handleMakeAllTester = () => {
    putMakeAllTester().then(() => {
      setPage((prev) => ({ ...prev, current: null }));
    });
  };

  const handleMakeAllNonTester = () => {
    putMakeAllNonTester().then(() => {
      setPage((prev) => ({ ...prev, current: null }));
    });
  };

  const handleToggleTester = () => {
    setToggling(true);
    putToggleTester(konsumen?.user_id)
      .then(({ data }) => {
        const childs = uiTable.current.getChildViews();
        if (childs) {
          const dtable = childs[0];

          const id = dtable.getSelectedId();

          dtable.updateItem(id.row, { ...data });
        }
      })
      .finally(() => setToggling(false));
  };

  useEffect(() => {
    uiTable.current = webix.ui(
      {
        rows: [
          {
            view: 'datatable',
            headerMenu: true,
            columns: [
              {
                id: 'is_tester',
                header: [
                  `
                      <div class="flex h-full items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                        </svg>  
                      </div>            
                    `,
                  { content: 'richSelectFilter' },
                ],
                width: 150,
                options: [
                  { id: '0', value: 'NON-TESTER' },
                  { id: '1', value: 'TESTER' },
                ],
                template: (o: any) => {
                  return `
                      <div class="flex h-full items-center justify-center">
                        ${
                          o.is_tester
                            ? `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 text-color-theme">
														<path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
													</svg>
													

                              `
                            : `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                            </svg>`
                        }
                      </div>`;
                },
              },
              {
                id: 'kategori_konsumen_id',
                header: [{ text: 'Kategori' }, { content: 'serverMultiSelectFilter' }],
                minWidth: 250,
                options: async function () {
                  const { data } = await getSemuaKategoriKonsumenOptions();

                  return data;
                },
                template: (o: any) =>
                  o.kategori_konsumen ? `<b>${o.kategori_konsumen.nama}</b>` : '-',
              },
              {
                id: 'nama',
                header: [{ text: 'Nama' }, { content: 'serverFilter' }],
                minWidth: 250,
                fillspace: true,
                sort: 'string',
              },
              {
                id: 'username',
                header: [{ text: 'Username' }, { content: 'serverFilter' }],
                minWidth: 200,
              },
              {
                id: 'email',
                header: [{ text: 'Email' }, { content: 'serverFilter' }],
                minWidth: 250,
                sort: 'string',
              },
              {
                id: 'no_hp',
                header: [{ text: 'No. HP' }, { content: 'serverFilter' }],
                minWidth: 200,
              },
              {
                id: 'tgl_lahir',
                header: [{ text: 'Tgl Lahir' }, { content: 'dateRangeFilter' }],
                minWidth: 200,
                hidden: true,
                template: function (obj: any) {
                  return moment(obj.tgl_lahir).format('DD MMMM YYYY');
                },
              },
              {
                id: 'created_at',
                header: [{ text: 'Bergabung Pada' }, { content: 'dateRangeFilter' }],
                minWidth: 200,
                template: function (obj: any) {
                  return moment(obj.created_at).format('DD MMMM YYYY, HH:mm');
                },
              },
            ],
            scroll: true,
            autoheight: true,
            leftSplit: 1,
            // autowidth:true,
            select: true,
            // footer:true,
            resizeColumn: true,
            url: async function (params: any) {
              try {
                const {
                  data: { data, current_page, last_page, total, per_page },
                } = await getSemuaKonsumen(webixTableParams(params), page.current);

                setPage((old) => ({
                  current: params ? old.current : current_page,
                  last: last_page,
                  total,
                  per_page,
                }));

                return data;
              } catch (e) {
                return [];
              }
            },
            on: {
              onAfterSelect: function (selection: any) {
                const item = (this as any).getItem(selection.id);
                setKonsumen(item);
              },
              onAfterUnSelect: function (selection: any) {
                setKonsumen(null);
              },
              onItemDblClick: function (selection: any) {
                const item = (this as any).getItem(selection.toString());
                navigate(`/konsumen/${item.user_id}`);
              },
            },
          },
        ],
      },
      webixTableRef.current,
    );

    return () => {
      setKonsumen(null);
      uiTable.current.destructor();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.current]);

  return (
    <React.Fragment>
      <PageHeader
        className="site-page-header"
        title="Daftar Konsumen Tester"
        extra={[
          <Button
            key="add-new-category"
            type="primary"
            disabled={!konsumen}
            loading={toggling}
            icon={<UserSwitchOutlined />}
            onClick={handleToggleTester}
          >
            Toggle Tester
          </Button>,
          <Dropdown
            key="other-actions"
            trigger={['click']}
            arrow
            overlay={
              <Menu>
                <Menu.Item>
                  <button onClick={handleMakeAllTester}>Jadikan semua tester</button>
                </Menu.Item>
                <Menu.Item>
                  <button onClick={handleMakeAllNonTester}>
                    Jadikan semua non-tester
                  </button>
                </Menu.Item>
              </Menu>
            }
          >
            <Button type="default" icon={<MoreOutlined />}></Button>
          </Dropdown>,
        ]}
      />
      <div className="p-5 flex items-center justify-between">
        <Pagination
          onChange={(current) => setPage((old) => ({ ...old, current }))}
          defaultCurrent={page.current}
          total={page.total}
          pageSize={page.per_page}
          showSizeChanger={false}
        />
      </div>
      <div ref={webixTableRef}></div>
    </React.Fragment>
  );
}
