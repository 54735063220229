import {
  GET_ALL_PESAN_REQUEST,
  GET_MORE_PESAN_REQUEST,
  READS_PESAN_ACTION,
  READS_PESAN_REQUEST,
} from '../types';

export const getAllPesanAction = () => ({
  type: GET_ALL_PESAN_REQUEST,
});

export const getMorePesanAction = (next_page_url: string) => ({
  type: GET_MORE_PESAN_REQUEST,
  next_page_url,
});

export const readsPesanAction = (pesan_id: number) => ({
  type: READS_PESAN_ACTION,
  pesan_id,
});

export const readsAllPesanAction = (pesan_ids: number[]) => ({
  type: READS_PESAN_REQUEST,
  pesan_ids,
});
