import { getDetailPesan } from '@/services/pesan';
import Pesan from '@/types/Pesan';
import { PageHeader, Skeleton } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function HalamanDetailPesan() {
  const { id } = useParams();
  const [pesan, setPesan] = useState<Pesan>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);

    getDetailPesan(id).then(({ data }) => {
      setLoading(false);
      setPesan(data);
    });
  }, [id]);

  if (loading || !pesan) {
    return <Skeleton active className="p-5" />;
  }

  return (
    <>
      <PageHeader title={'Pesan'} subTitle={'Detail pesan dari pengguna'} />
      <section className="p-5 bg-white rounded mx-5 my">
        <span className="text-gray-400">Dibuat pada</span>
        <p>{moment(pesan.created_at).format('DD MMMM yyyy HH:mm')}</p>
        <span className="text-gray-400">Subjek</span>
        <h1>{pesan.subject}</h1>
        <span className="text-gray-400">Dari</span>
        <p>
          {pesan.full_name} ({pesan.email})
        </p>
        <span className="text-gray-400">Isi Pesan</span>
        <p className="mb-0">{pesan.content}</p>
      </section>
    </>
  );
}
