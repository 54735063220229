export const SIGNIN_USER_REQUEST = 'SIGNIN_USER_REQUEST';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNIN_USER_FAILURE = 'SIGNIN_USER_FAILURE';

export const GET_ALL_BADGES_REQUEST = 'GET_ALL_BADGES_REQUEST';
export const GET_ALL_BADGES_SUCCESS = 'GET_ALL_BADGES_SUCCESS';
export const GET_ALL_BADGES_FAILURE = 'GET_ALL_BADGES_FAILURE';

export const GET_ALL_PESAN_REQUEST = 'GET_ALL_PESAN_REQUEST';
export const GET_ALL_PESAN_SUCCESS = 'GET_ALL_PESAN_SUCCESS';
export const GET_ALL_PESAN_FAILURE = 'GET_ALL_PESAN_FAILURE';
export const GET_MORE_PESAN_REQUEST = 'GET_MORE_PESAN_REQUEST';
export const GET_MORE_PESAN_SUCCESS = 'GET_MORE_PESAN_SUCCESS';
export const GET_MORE_PESAN_FAILURE = 'GET_MORE_PESAN_FAILURE';
export const READS_PESAN_ACTION = 'READS_PESAN_ACTION';
export const READS_PESAN_REQUEST = 'READS_PESAN_REQUEST';
export const READS_PESAN_SUCCESS = 'READS_PESAN_SUCCESS';
export const READS_PESAN_FAILURE = 'READS_PESAN_FAILURE';

export const GET_ALL_NOTIFICATION_REQUEST = 'GET_ALL_NOTIFICATION_REQUEST';
export const GET_ALL_NOTIFICATION_SUCCESS = 'GET_ALL_NOTIFICATION_SUCCESS';
export const GET_ALL_NOTIFICATION_FAILURE = 'GET_ALL_NOTIFICATION_FAILURE';
export const GET_MORE_NOTIFICATION_REQUEST = 'GET_MORE_NOTIFICATION_REQUEST';
export const GET_MORE_NOTIFICATION_SUCCESS = 'GET_MORE_NOTIFICATION_SUCCESS';
export const GET_MORE_NOTIFICATION_FAILURE = 'GET_MORE_NOTIFICATION_FAILURE';
export const READS_NOTIFICATION_ACTION = 'READ_NOTIFICATION_ACTION';
export const READS_NOTIFICATION_REQUEST = 'READS_NOTIFICATION_REQUEST';
export const READS_NOTIFICATION_SUCCESS = 'READS_NOTIFICATION_SUCCESS';
export const READS_NOTIFICATION_FAILED = 'READS_NOTIFICATION_FAILED';

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

export const OVERRIDE_USER_ACTION = 'OVERRIDE_USER_ACTION';

export const CLEAR_SESI = 'CLEAR_SESI';
