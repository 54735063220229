import ModalKategori, { ModalKategoriItem } from '@/components/ModalKategoriComponent';
import { webixTableParams } from '@/helpers/webix_helper';
import {
  getSemuaFilteredProduk,
  getSemuaKategoriProduk,
  getSemuaKategoriProdukCascader,
  getSemuaProduk,
  postTambahKategoriProduk,
  putUpdateKategoriProduk,
} from '@/services/produk';
import Kategori from '@/types/Kategori';
import Paging from '@/types/Paging';
import Produk from '@/types/Produk';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Empty, Form, Input, PageHeader, Select, Skeleton } from 'antd';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function HalamanDaftarProduk() {
  const [formFilter] = Form.useForm();
  const [modalKategoriItem, setModalKategoriItem] = useState<ModalKategoriItem | null>(
    null,
  );
  const [kategoris, setKategoris] = useState<{
    data: Kategori[];
    loading: boolean;
  }>({ data: [], loading: true });
  const [loadingHalaman, setLoadingHalaman] = useState<boolean>(false);
  const [filtering, setFiltering] = useState<boolean>(false);
  const [produks, setProduks] = useState<Paging<Produk>>({
    data: [],
    loading: true,
  });

  const handleMuatLebihBanyak = () => {
    setLoadingHalaman(true);
    getSemuaProduk(produks.next_page_url)
      .then(({ data }: AxiosResponse<any>) => {
        setProduks((old) => ({
          ...data,
          data: [...old.data, ...data.data],
        }));
      })
      .finally(() => setLoadingHalaman(false));
  };

  const handleFilterProduk = (values: any) => {
    setFiltering(true);
    getSemuaFilteredProduk(
      webixTableParams({
        filter: {
          ...(values.nama && { nama: values.nama }),
          ...(values.is_active && { is_active: values.is_active }),
        },
      }),
    )
      .then(({ data }) => {
        setProduks({ ...data, loading: false });
      })
      .finally(() => setFiltering(false));
  };

  useEffect(() => {
    getSemuaKategoriProduk().then(({ data }) => setKategoris({ data, loading: false }));
    getSemuaProduk()
      .then(({ data }: AxiosResponse<any>) => {
        setProduks({ ...data, loading: false });
      })
      .finally(() => setProduks((old) => ({ ...old, loading: false })));
  }, []);

  return (
    <>
      <ModalKategori
        tipe="Produk"
        visible={!!modalKategoriItem}
        kategoriItem={modalKategoriItem}
        getCascaderAction={getSemuaKategoriProdukCascader}
        postAddAction={(params) => postTambahKategoriProduk(params)}
        putUpdateAction={(kategori_id, params) =>
          putUpdateKategoriProduk(kategori_id, params)
        }
        onFinishAdd={(kategori: Kategori) =>
          setKategoris((old) => ({ loading: false, data: [...old.data, kategori] }))
        }
        onFinishUpdate={(kategori: Kategori) =>
          setKategoris((old) => ({
            loading: false,
            data: old.data.map((item) => {
              if (item.id === modalKategoriItem?.item?.id) {
                return kategori;
              }
              return item;
            }),
          }))
        }
        onCancel={() => setModalKategoriItem(null)}
      />
      <PageHeader
        title="Daftar Produk"
        subTitle="Daftar semua produk yang tersedia"
        extra={[
          <Button
            key={'add-kategori'}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setModalKategoriItem({ tipe: 'TAMBAH' })}
          >
            Tambah Kategori
          </Button>,
          <Link key={'add-produk'} to="tambah">
            <Button type="primary" icon={<PlusOutlined />}>
              Tambah Produk
            </Button>
          </Link>,
        ]}
      />

      <section className="p-5">
        {kategoris.loading && <Skeleton.Input active block className="mb-5" />}
        {!kategoris.loading && (
          <div className="flex items-start space-x-3 overflow-x-auto">
            {kategoris.data?.map((kategori) => (
              <Link
                key={kategori.id}
                to={`/kategori/produk/${kategori.id}`}
                className="my-kategori-produk text-lg h-20 px-5 py-3 rounded-md relative flex-none max-w-xs"
              >
                {kategori.nama}
              </Link>
            ))}
          </div>
        )}
        {produks.loading && (
          <>
            <Skeleton.Input block active className="mb-1" />
            <Skeleton.Input block active className="mb-1" />
            <Skeleton.Input block active className="mb-1" />
          </>
        )}
        {!produks.loading && (
          <>
            <div className="mb-3 mt-5">
              <Form
                form={formFilter}
                onFinish={handleFilterProduk}
                initialValues={{
                  is_active: null,
                }}
                layout="horizontal"
                className="flex flex-col md:flex-row w-full -space-y-2 md:space-x-2 md:space-y-0"
              >
                <div className="flex-grow">
                  <Form.Item name={'nama'}>
                    <Input.Search
                      size="large"
                      placeholder="Cari produk"
                      className="w-full"
                      allowClear
                      onSearch={() => formFilter.submit()}
                    />
                  </Form.Item>
                </div>

                <div className="w-full md:w-2/12 flex-none">
                  <Form.Item name={'is_active'}>
                    <Select size="large" onChange={() => formFilter.submit()}>
                      <Select.Option value={null}>Semua</Select.Option>
                      <Select.Option value="1">Aktif</Select.Option>
                      <Select.Option value="0">Non Aktif</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </Form>
            </div>

            <div className="relative">
              {filtering && (
                <div className="absolute inset-0 flex items-center justify-center z-50 bg-white bg-opacity-60">
                  <LoadingOutlined className="text-3xl" />
                </div>
              )}
              {produks.data.length === 0 && (
                <div className="py-5 rounded bg-white mb-0">
                  <Empty
                    description={
                      <p className="text-gray-500">Belum ada produk tersedia</p>
                    }
                  />
                </div>
              )}
              {produks.data.length > 0 && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
                  {produks.data.map((produk) => (
                    <div
                      className={`border rounded bg-white overflow-hidden shadow ${
                        !produk.is_active && 'border-red-500'
                      }`}
                      key={produk.id}
                    >
                      <img src={produk.banner} alt={produk.nama} className="w-full" />
                      <div className="px-5 py-3">
                        <h3 className="leading-tight text-lg mb-0">
                          <Link to={`/produk/${produk.id}`} className="font-bold">
                            {produk.nama}
                          </Link>
                        </h3>
                        <Link to={`/kategori/produk/${produk.kategori.id}`}>
                          <span className="leading-tight">{produk.kategori.nama}</span>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {produks.next_page_url && (
                <div className="flex items-center justify-center mt-10">
                  <Button
                    onClick={handleMuatLebihBanyak}
                    loading={loadingHalaman}
                    type="default"
                    shape="round"
                  >
                    Muat lebih banyak
                  </Button>
                </div>
              )}
            </div>
          </>
        )}
      </section>
    </>
  );
}
