import { combineReducers } from 'redux';
import sesi from '@/store/reducers/sesi';
import notifikasi from '@/store/reducers/notifikasi';
import badge from '@/store/reducers/badge';
import pesan from '@/store/reducers/pesan';

export default combineReducers({
  sesi,
  notifikasi,
  badge,
  pesan,
});
