import { readsPesanAction } from '@/store/actions/pesan';
import Pesan from '@/types/Pesan';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface Props {
  pesan: Pesan;
}
export default function PesanItem({ pesan }: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOnRead = () => {
    if (!pesan.read_at) dispatch(readsPesanAction(pesan.id));
    navigate(`pesan/${pesan.id}`);
  };

  return (
    <div
      onClick={handleOnRead}
      className="flex space-x-5 rounded px-5 py-3 hover:bg-gray-200 cursor-pointer"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`h-8 w-8 flex-none ${
          pesan.read_at ? 'text-gray-500' : 'text-color-theme'
        }`}
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
      </svg>
      <div className="flex-grow">
        <h6
          className={`font-bold my-0 leading-tight ${
            pesan.read_at ? 'text-gray-500' : 'text-black'
          }`}
        >
          {pesan.email}
        </h6>
        <p
          className={`mb-0 leading-tight ${
            pesan.read_at ? 'text-gray-500' : 'text-black'
          }`}
        >
          {pesan.subject}
        </p>
        <span className="text-gray-400 text-xs">
          {moment(pesan.created_at).fromNow()}
        </span>
      </div>
    </div>
  );
}
