import Paging from '@/types/Paging';
import Pesan from '@/types/Pesan';
import {
  GET_ALL_PESAN_FAILURE,
  GET_ALL_PESAN_REQUEST,
  GET_ALL_PESAN_SUCCESS,
  GET_MORE_PESAN_FAILURE,
  GET_MORE_PESAN_REQUEST,
  GET_MORE_PESAN_SUCCESS,
  READS_PESAN_ACTION,
  READS_PESAN_FAILURE,
  READS_PESAN_SUCCESS,
} from '../types';

export interface PesanValueType {
  loading: boolean;
  list: Paging<Pesan>;
  message?: string;
  reads: number[];
}

export interface PesanActionType {
  type: string;
  list: Paging<Pesan>;
  message?: string;
  pesan_id?: number;
}

const initialState: PesanValueType = {
  loading: false,
  list: {
    data: [],
    total: 0,
  },
  message: null,
  reads: [],
};

export default function pesanReducer(
  state: PesanValueType = initialState,
  action: PesanActionType,
): PesanValueType {
  const { type, list, message, pesan_id } = action;

  switch (type) {
    case GET_ALL_PESAN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_PESAN_SUCCESS:
      return {
        ...state,
        loading: false,
        message: null,
        list,
      };
    case GET_ALL_PESAN_FAILURE:
      return {
        ...state,
        loading: false,
        message,
      };
    case GET_MORE_PESAN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MORE_PESAN_SUCCESS:
      return {
        ...state,
        loading: false,
        list: {
          ...list,
          data: [...state.list.data, ...list.data],
        },
        message: null,
      };
    case GET_MORE_PESAN_FAILURE:
      return {
        ...state,
        loading: false,
        message,
      };
    case READS_PESAN_ACTION:
      return {
        ...state,
        list: {
          ...state.list,
          data: state.list.data.map((item) => {
            if (item.id === pesan_id) {
              return {
                ...item,
                read_at: item.updated_at,
              };
            }
            return item;
          }),
        },
        reads: state.reads.includes(pesan_id) ? state.reads : [...state.reads, pesan_id],
      };
    case READS_PESAN_SUCCESS:
      return {
        ...state,
        reads: [],
        message: null,
      };
    case READS_PESAN_FAILURE: {
      return {
        ...state,
        message,
      };
    }
    default:
      return state;
  }
}
