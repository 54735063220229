import axios from './axios';

export const getChartPendapatan = (tipe: string, value: string) => {
  if (value)
    return axios.get(`/admin/pesanan/chart/pendapatan?tipe=${tipe}&value=${value}`);
  return axios.get(`/admin/pesanan/chart/pendapatan?tipe=${tipe}`);
};

export const getChartPendapatanByProduk = (tipe: string, value: string) => {
  if (value)
    return axios.get(
      `/admin/pesanan/chart/pendapatan/produk?tipe=${tipe}&value=${value}`,
    );
  return axios.get(`/admin/pesanan/chart/pendapatan/produk?tipe=${tipe}`);
};
