import axios from './axios';

export const getListTagihan = (params: any, page: number = 1) => {
  if (params) {
    return axios.get(`/admin/tagihan?page=${page}&${params}`);
  }
  return axios.get(`/admin/tagihan?page=${page}`);
};

export const getDetailTagihan = (id: string) => {
  return axios.get(`/admin/tagihan/${id}`);
};
