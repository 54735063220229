import { getSemuaPesan, postReadsPesan } from '@/services/pesan';
import {
  GET_ALL_PESAN_FAILURE,
  GET_ALL_PESAN_SUCCESS,
  GET_MORE_PESAN_FAILURE,
  GET_MORE_PESAN_SUCCESS,
  READS_PESAN_FAILURE,
  READS_PESAN_SUCCESS,
} from '@/store/types';
import { call, put } from 'redux-saga/effects';

export function* getAllPesanHandler() {
  try {
    const { data } = yield call(getSemuaPesan);

    yield put({ type: GET_ALL_PESAN_SUCCESS, list: data });
  } catch (e) {
    yield put({
      type: GET_ALL_PESAN_FAILURE,
      message: e.response?.data?.message || e.message,
    });
  }
}

export function* getMorePesanHandler(action: any) {
  try {
    const { data } = yield call(getSemuaPesan, action.next_page_url);

    yield put({ type: GET_MORE_PESAN_SUCCESS, list: data });
  } catch (e) {
    yield put({
      type: GET_MORE_PESAN_FAILURE,
      message: e.response?.data?.message || e.message,
    });
  }
}

export function* readsAllPesanHandler(action: any) {
  try {
    if (action.pesan_ids.length > 0) {
      const { data } = yield call(postReadsPesan, action.pesan_ids);
      yield put({ type: READS_PESAN_SUCCESS, badge: data });
    }
  } catch (e) {
    yield put({
      type: READS_PESAN_FAILURE,
      message: e.response?.data?.message || e.message,
    });
  }
}
