import axios from './axios';

export const getSemuaPesan = (next_page_url: string = null) => {
  if (next_page_url) return axios.get(next_page_url);
  return axios.get(`/admin/pesan`);
};

export const getDetailPesan = (id: string) => {
  return axios.get(`/admin/pesan/${id}`);
};

export const postReadsPesan = (pesan_ids: number[]) => {
  return axios.post(`/admin/pesan/reads`, {
    pesan_ids,
  });
};
