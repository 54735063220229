import axios from './axios';

export const getProfileDetail = () => {
  return axios.get('/profile');
};

export const postUpdatePassword = (params: any) => {
  return axios.post('/profile/update-password', params);
};

export const postUpdateProfile = (params: any) => {
  return axios.post('/profile/admin/update', params);
};

export const getPermissions = () => {
  return axios.get('/profile/permissions');
};
