import axios from './axios';

export const getUlasanProduk = (produk_id: string, page: number = 1) => {
  return axios.get(`/admin/produk/${produk_id}/ulasan?page=${page}`);
};

export const getRatingProduk = (produk_id: string) => {
  return axios.get(`/admin/produk/${produk_id}/rating`);
};

export const getDetailProduk = (produk_id: string) => {
  return axios.get(`/admin/produk/${produk_id}`);
};

export const getDetailProdukWithStok = (produk_id: string) => {
  return axios.get(`/admin/produk/${produk_id}/stok`);
};

export const getDetailProdukWithDiskon = (produk_id: string) => {
  return axios.get(`/admin/produk/${produk_id}/diskon`);
};

export const getSemuaProduk = (
  next_page_url: string | null = null,
  kategori_id: string | null = null,
) => {
  if (next_page_url) return axios.get(next_page_url);
  if (kategori_id) return axios.get(`/admin/produk?kategori_id=${kategori_id}`);
  return axios.get(`/admin/produk`);
};

export const getMostSeenProducts = (limit: number = null) => {
  if (limit) return axios.get(`/produk/most-seen?limit=${limit}`);
  return axios.get(`/produk/most-seen`);
};

export const getSemuaFilteredProduk = (params: any, page: number = 1) => {
  if (params) return axios.get(`/admin/produk?${params}`);
  return axios.get(`/admin/produk?page=${page}`);
};

export const postTambahProduk = (params: any) => {
  return axios.post('/admin/produk', params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const putUpdateProduk = (produk_id: string, params: any) => {
  return axios.post(`/admin/produk/${produk_id}`, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const putUpdateActivateProduk = (produk_id: string) => {
  return axios.put(`/admin/produk/${produk_id}/aktivasi`);
};

export const putUpdateDeactivateProduk = (produk_id: string) => {
  return axios.put(`/admin/produk/${produk_id}/deaktivasi`);
};

export const putUpdateStokProduk = (produk_id: string, params: any) => {
  return axios.put(`/admin/produk/${produk_id}/stok`, params);
};

export const putUpdateDiskonProduk = (
  produk_id: string,
  diskon_id: number,
  params: any,
) => {
  return axios.put(`/admin/produk/${produk_id}/diskon/${diskon_id}`, params);
};

export const putToggleDiskonProduk = (
  produk_id: string,
  diskon_id: number,
  checked: boolean,
) => {
  return axios.put(`/admin/produk/${produk_id}/diskon/${diskon_id}/toggle`, {
    is_aktif: checked ? 1 : 0,
  });
};

export const deleteProduk = (produk_id: string) => {
  return axios.delete(`/admin/produk/${produk_id}`);
};

export const postSimpanTanggapanProduk = (produk_id: string, values: any) => {
  return axios.post(`/admin/produk/${produk_id}/ulasan`, values);
};

export const deleteHapusTanggapanProduk = (produk_id: string, tanggapan_id: number) => {
  return axios.delete(`/admin/produk/${produk_id}/ulasan/${tanggapan_id}`);
};

export const getSemuaKategoriProduk = () => {
  return axios.get(`/admin/kategori/produk`);
};

export const getKategoriProdukDetail = (kategori_id: string) => {
  return axios.get(`/admin/kategori/produk/${kategori_id}`);
};

export const getSemuaKategoriProdukCascader = () => {
  return axios.get('/enums/cascader/kategori-produk');
};

export const getSemuaKategoriProdukOptions = () => {
  return axios.get(`/enums/kategori-produk`);
};

export const postAddDiskonProduk = (produk_id: string, params: any) => {
  return axios.post(`/admin/produk/${produk_id}/diskon`, params);
};

export const postTambahKategoriProduk = (values: any) => {
  return axios.post('/admin/kategori/produk', values);
};

export const putUpdateKategoriProduk = (kategori_id: string, values: any) => {
  return axios.put(`/admin/kategori/produk/${kategori_id}`, values);
};

export const deleteHapusKategoriProduk = (kategori_id: string) => {
  return axios.delete(`/admin/kategori/produk/${kategori_id}`);
};

export const postCariProduk = (query: string = '', limit: number = null) => {
  if (limit) return axios.post(`/produk?q=${query}&limit=${limit}`);
  return axios.post(`/produk?q=${query}`);
};
