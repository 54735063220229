import { ReactNode, useEffect, useState } from 'react';
import { Menu, message, notification } from 'antd';
import {
  HomeOutlined,
  ShoppingOutlined,
  UserOutlined,
  SettingOutlined,
  QuestionCircleOutlined,
  // BookOutlined,
  BellFilled,
  DollarOutlined,
  StarOutlined,
  ProfileOutlined,
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { getFCMToken, onMessageListener } from '@/services/firebase';
import { postStoreFCMToken } from '@/services/notifikasi';
import HeaderComponent from './HeaderComponent';

const mainBgColor = 'bg-gray-200';

export default function WrapperComponent({ children }: { children: ReactNode }) {
  const location = useLocation();
  const [showSidebar, setShowSidebar] = useState<boolean>(false);

  useEffect(() => {
    getFCMToken()
      .then(postStoreFCMToken)
      .catch((e) => {
        message.error(e.message);
      });

    onMessageListener()
      .then((payload: any) => {
        notification.open({
          message: payload.notification.title,
          description: payload.notification.body,
          icon: <BellFilled className="text-2xl text-green-700" />,
          onClick: () => {
            //   console.log('Notification Clicked!');
          },
        });
      })
      .catch((err) => console.log('failed: ', err));
  }, []);

  useEffect(() => {
    setShowSidebar(false);
  }, [location.pathname]);

  return (
    <>
      <HeaderComponent onClickSidebarMenu={() => setShowSidebar((prev) => !prev)} />
      <main className={`${mainBgColor} flex`}>
        <nav
          className={`my-sidebar bg-black bg-opacity-75 md:bg-white md:bg-opacity-100 ${
            showSidebar ? 'flex' : 'hidden md:flex'
          } fixed inset-0 md:top-0 md:bottom-0 md:left-0 md:right-auto md:w-[250px] lg:w-[23vw] z-50 md:z-40`}
        >
          <div className="w-72 md:w-full bg-white flex flex-col flex-none">
            <div className="px-5 pb-5 mt-5 flex space-x-3 flex-none border-b">
              <div className="w-28 lg:w-32 flex-none flex items-center">
                <img src="/logo_mysawit.png" alt="Logo PPKS" className="w-full" />
              </div>

              <div className="hidden xl:flex flex-col justify-center flex-grow mt-3 border-l pl-3 space-y-1 py-1">
                <span className="text-gray-500 uppercase tracking-wider">
                  Administrator
                </span>
              </div>
            </div>
            <div className="py-6 overflow-y-auto flex-grow">
              <Menu mode="inline" defaultSelectedKeys={['dashboard']}>
                <Menu.Item key={'dashboard'} icon={<HomeOutlined />}>
                  <Link to="/">Dashboard</Link>
                </Menu.Item>
                <Menu.SubMenu key={'marketing'} title="Marketing" icon={<UserOutlined />}>
                  <Menu.Item>
                    <Link to="/marketing/tambah">Tambah Marketing</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/marketing">Daftar Marketing</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/marketing/struktur">Struktur Marketing</Link>
                  </Menu.Item>
                  <Menu.SubMenu title="Laporan Marketing">
                    <Menu.Item>
                      <Link to="/marketing/laporan/annual">Annual Report</Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link to="/marketing/laporan/monthly">Monthly Report</Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link to="/marketing/laporan/daily">Daily Report</Link>
                    </Menu.Item>
                  </Menu.SubMenu>
                </Menu.SubMenu>
                <Menu.SubMenu key={'produk'} title="Produk" icon={<StarOutlined />}>
                  <Menu.Item>
                    <Link to="/produk/tambah">Tambah Produk</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/produk">Daftar Produk</Link>
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu key={'konsumen'} title="Konsumen" icon={<UserOutlined />}>
                  <Menu.Item>
                    <Link to="/konsumen/tambah">Tambah Konsumen</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/konsumen">Daftar Konsumen</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/konsumen/struktur">Struktur Konsumen</Link>
                  </Menu.Item>
                </Menu.SubMenu>
                {/* <Menu.SubMenu key={'blog'} title="Blog" icon={<BookOutlined />}>
                  <Menu.Item>
                    <Link to="/blog/tulis">Tulis Artikel</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/blog">Daftar Artikel</Link>
                  </Menu.Item>
                </Menu.SubMenu> */}
                <Menu.SubMenu key={'keuangan'} title="Keuangan" icon={<DollarOutlined />}>
                  <Menu.Item>
                    <Link to="/keuangan/tagihan">Tagihan</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/keuangan/pendapatan">Pendapatan</Link>
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu
                  key={'pengaturan'}
                  title="Pengaturan"
                  icon={<SettingOutlined />}
                >
                  <Menu.Item>
                    <Link to="/pengaturan/konfigurasi">Konfigurasi</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/pengaturan/surat-pernyataan">Surat Pernyataan</Link>
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu key={'lainnya'} title="Lainnya" icon={<ProfileOutlined />}>
                  <Menu.Item>
                    <Link to="/lainnya/karousel">Karousel</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/lainnya/popup">Popup</Link>
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.Item key={'pesanan'} icon={<ShoppingOutlined />}>
                  <Link to="/pesanan">Pesanan</Link>
                </Menu.Item>
                <Menu.Item key={'faq'} icon={<QuestionCircleOutlined />}>
                  <Link to="/faq">F.A.Q</Link>
                </Menu.Item>
              </Menu>
            </div>
          </div>
          <div
            className="flex-grow"
            onClick={() => setShowSidebar((prev) => !prev)}
          ></div>
        </nav>
        <div className="relative flex flex-col flex-grow md:ml-[250px] lg:ml-[23vw] min-h-[100vh] overflow-x-auto">
          {children}
        </div>
      </main>
    </>
  );
}
