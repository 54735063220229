import React, { useState, useEffect } from 'react';
import { Skeleton, Tabs, Steps, Button, PageHeader } from 'antd';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  ClockCircleFilled,
  ShoppingOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { getDetailTagihan } from '@/services/tagihan';
import Tagihan from '@/types/Tagihan';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import { formatCurrency } from '@/helpers/order_helper';
import { capitalize } from 'lodash';

const Row = ({ children, className = '' }: any) => (
  <div
    className={`flex flex-col md:flex-row justify-between space-y-1 md:space-y-0 md:space-x-5 first:pt-0 pt-2 ${className}`}
  >
    {children}
  </div>
);

export default function HalamanDetailTagihan() {
  const { id } = useParams();

  const [tagihan, setTagihan] = useState<Tagihan>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getDetailTagihan(id).then(({ data }) => {
      setTagihan(data);
      setLoading(false);
    });
  }, [id]);

  if (loading) return <Skeleton active className="p-5" />;

  return (
    <>
      <PageHeader
        title="Detail Tagihan"
        subTitle="Detail informasi tagihan"
        extra={[
          <React.Fragment key={'unduh-receipt'}>
            {tagihan?.receipt && (
              <Button
                icon={<DownloadOutlined />}
                href={tagihan.receipt}
                type="primary"
                target="_blank"
              >
                Unduh Struk
              </Button>
            )}
          </React.Fragment>,
          <Link key={'key-2'} to={`/pesanan/${tagihan.reference_id}`}>
            <Button icon={<ShoppingOutlined />} type="primary">
              Lihat Pesanan
            </Button>
          </Link>,
        ]}
      />
      <section className="px-5 pb-5">
        {!loading && (
          <Tabs type="card" defaultActiveKey="1">
            <Tabs.TabPane tab={'Informasi'} key="1">
              <div className="w-full space-y-2 p-5 rounded bg-white divide-y divide-dashed">
                <Row>
                  <span>Status Pembayaran</span>
                  <span
                    className={`${
                      tagihan.is_finished
                        ? 'bg-green-600'
                        : tagihan.is_canceled
                        ? 'bg-red-600'
                        : 'bg-yellow-500'
                    } rounded-full text-white px-4 py-[3px] text-[small]`}
                  >
                    {tagihan.is_finished ? (
                      <CheckCircleFilled />
                    ) : tagihan.is_canceled ? (
                      <CloseCircleFilled />
                    ) : (
                      <ClockCircleFilled />
                    )}
                    &nbsp;{' '}
                    {tagihan?.status === 'DIBUAT'
                      ? 'Menunggu Pembayaran'
                      : capitalize(tagihan.status)}
                  </span>
                </Row>
                <Row>
                  <span>Dibuat Pada</span>
                  <span>
                    {moment(new Date(tagihan.created_at)).format(
                      'dddd, DD MMMM yyyy HH:mm',
                    )}
                  </span>
                </Row>
                <Row>
                  <span>Nomor Tagihan</span>
                  <span>{tagihan.id}</span>
                </Row>
                <Row>
                  <span>Metode Pembayaran</span>
                  <img
                    className="w-20"
                    src={tagihan.metode_pembayaran_logo}
                    alt={tagihan.metode_pembayaran}
                  />
                </Row>
                {tagihan?.payload_pg?.payment_type === 'gopay' && (
                  <Row>
                    <span>QR Code</span>
                    <div className="flex justify-end flex-grow items-end">
                      <img
                        className="max-w-sm"
                        src={
                          tagihan.response_pg?.actions?.find(
                            (i) => i.name === 'generate-qr-code',
                          ).url
                        }
                        alt="QR CODE"
                      />
                    </div>
                  </Row>
                )}
                {tagihan?.payload_pg?.payment_type === 'qris' && (
                  <Row>
                    <span>QR Code</span>
                    <div className="flex justify-end flex-grow items-end">
                      <img
                        className="max-w-sm"
                        src={
                          tagihan.response_pg?.actions?.find(
                            (i) => i.name === 'generate-qr-code',
                          ).url
                        }
                        alt="QR CODE"
                      />
                    </div>
                  </Row>
                )}
                {tagihan?.payload_pg?.payment_type === 'bank_transfer' && (
                  <Row>
                    <span>Nomor Virtual Account</span>
                    <span>
                      {
                        tagihan?.response_pg?.va_numbers?.find(
                          (i) => i.bank === tagihan.payload_pg?.bank_transfer?.bank,
                        )?.va_number
                      }
                    </span>
                  </Row>
                )}
                {tagihan?.payload_pg?.payment_type === 'echannel' && (
                  <>
                    <Row>
                      <span>Kode Pembayaran</span>
                      <span>{tagihan?.response_pg?.bill_key}</span>
                    </Row>
                    <Row>
                      <span>Kode Perusahaan</span>
                      <span>{tagihan?.response_pg?.biller_code}</span>
                    </Row>
                  </>
                )}
                <Row>
                  <span>Deskripsi</span>
                  <span>{tagihan.deskripsi}</span>
                </Row>
                <Row>
                  <span>Nilai Ditagihkan</span>
                  <span>{tagihan?.nilai_diff}</span>
                </Row>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab={'Uraian'} key="2">
              <div className="mb-3 w-full space-y-2 p-5 rounded bg-white divide-y divide-dashed">
                {tagihan.payload_pg.item_details.map((item) => (
                  <Row key={`item-${item.id}`}>
                    {['kode-unik', 'ongkos-kirim', 'biaya-tagihan'].includes(item.id) ? (
                      <span>{item.name}</span>
                    ) : (
                      <div className="flex flex-grow flex-col">
                        <Link to={`/produk/${item.id}`}>{item.name}</Link>
                        <span className="text-gray-400">
                          {item.quantity} &times; {formatCurrency(item.price)}
                        </span>
                      </div>
                    )}

                    <span className="text-lg">
                      {formatCurrency(item.price * item.quantity)}
                    </span>
                  </Row>
                ))}
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab={'Riwayat'} key="3">
              <div className="p-5 bg-white rounded">
                <Steps
                  progressDot
                  current={tagihan.riwayat.length - 1}
                  direction="vertical"
                >
                  {tagihan.riwayat.map((item) => (
                    <Steps.Step
                      key={`timeline-${item.id}`}
                      title={moment(item.created_at).format('DD MMMM yyyy, HH:mm')}
                      description={item.status}
                    />
                  ))}
                </Steps>
              </div>
            </Tabs.TabPane>
          </Tabs>
        )}
      </section>
    </>
  );
}
