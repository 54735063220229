import { Form, Input, Button, PageHeader, message } from 'antd';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector } from '@/hooks/redux_hooks';
import { updateProfileAction } from '@/store/actions/sesi';
import { CheckCircleFilled } from '@ant-design/icons';
import { useEffect } from 'react';
import { parseError } from '@/helpers/form_helper';
import { usePrevious } from '@/hooks/additional_hooks';

export default function HalamanUpdateProfile() {
  const dispatch = useDispatch();

  const session = useAppSelector((state) => state.sesi.user);
  const request_profile = useAppSelector((state) => state.sesi.request_profile);
  const previously_loading = usePrevious(request_profile.loading);

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const handleUpdateProfile = (params: any) => {
    dispatch(updateProfileAction(params));
  };

  useEffect(() => {
    if (request_profile.errors) {
      parseError(form, {
        response: {
          data: {
            errors: request_profile.errors,
          },
        },
      });
    }

    if (previously_loading && !request_profile.errors) {
      navigate('/profile');
      message.success('Profile berhasil diperbaharui');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request_profile]);

  return (
    <>
      <PageHeader
        onBack={() => navigate('/profile')}
        breadcrumb={{
          routes: [
            { path: '/profile', breadcrumbName: 'Profile' },
            { path: '/profile/perbaharui', breadcrumbName: 'Perbaharui Informasi' },
          ],
          itemRender: (route, _, routes) => {
            const last = routes.indexOf(route) === routes.length - 1;
            return last ? (
              <span>{route.breadcrumbName}</span>
            ) : (
              <Link to={route.path}>{route.breadcrumbName}</Link>
            );
          },
        }}
        title="Perbaharui Profile"
        subTitle="Perbaharui informasi akun"
      />
      <section className="p-5">
        <div className="rounded bg-white p-5 shadow">
          <Form
            form={form}
            className="md:w-7/12"
            layout={'vertical'}
            onFinish={handleUpdateProfile}
            initialValues={session!}
          >
            <Form.Item
              name="nama"
              label="Nama"
              rules={[{ required: true, message: 'Nama lama diperlukan' }]}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: 'Email lama diperlukan' },
                { type: 'email', message: 'Format email tidak valid' },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Form>
          <div className="mt-5">
            <Button
              size="large"
              icon={<CheckCircleFilled />}
              loading={request_profile.loading}
              onClick={() => form.submit()}
              type="primary"
              className="w-full md:w-auto"
            >
              Simpan Perubahan
            </Button>
          </div>
        </div>
      </section>
    </>
  );
}
